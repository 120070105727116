import { createStore } from "vuex"
import getters from "./getters"
import app from "./modules/app"

const modules = { app: app }
const store = createStore({
  modules,
  getters,
})

export default store
