import axios from "axios"
import qs from "qs"
import AxiosRequest from "../utils/axiosrequest"
import router from "@/router"
import store from "@/store"

// 创建一个axios实例
const service = axios.create({
  // baseURL: 'http://localhost:21657/api',
  baseURL: "https://book.healthland.com.my",
  //baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
  // 允许带cookies
  // 前端使用了 withCredentials: true，而恰恰这个使用是不允许AllowAnyOrigin()的，意思就是不允许返回的Access-Control-Allow-Origin:*。
  withCredentials: true,
  transformRequest(data) {
    // `transformRequest` 允许在向服务器发送前，修改请求数据
    // 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
    return qs.stringify(data)
  },
})

// 请求拦截器
const interceptorRequst = (config) => {
  const configTemp = config
  if (config.method == "post") {
    // 判断请求是否为post请求
    configTemp.headers["Content-Type"] = "application/x-www-form-urlencoded" // 通过在请求头内加入Content-Type解决
  } else if (config.method == "get") {
    // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
    configTemp.paramsSerializer = (params) =>
      qs.stringify(params, { arrayFormat: "repeat" })
  }
  if (store.getters.token) {
    //configTemp.headers.Authorization = `Bearer ${getToken()}`
  }
  store.commit("app/SET_LOADING", true)
  return config
}

const http = new AxiosRequest(service, interceptorRequst)

// 配置通用请求动画
const loading = null
// 是否正在刷新的标记
let isRefreshing = false
// 重试队列，每一项将是一个待执行的函数形式
let requests = []

const routerRedirect = ({ path = "/auth/login", redirect }) => {
  if (router.currentRoute.path != "/auth/login") {
    setTimeout(() => {
      router.replace({ path, query: { redirect } })
    }, 1200)
  }
}

// 返回拦截器
http.useResponseInterceptor(
  async (response) => {
    store.commit("app/SET_LOADING", false)
    let data = {}
    if (response && response.data) {
      const resStatus = Number(response.status)
      data = response.data
      if (resStatus == 200) {
        data = response.data
        if (
          response.data.status != undefined &&
          response.data.message != undefined
        ) {
          // 是否为固定的格式
          if (response.data.status == 0) {
            // 旧版为0，新版为1
            return Promise.resolve(response.data)
          }
        }
      }
      // console.log(response.data);
      return Promise.resolve(response.data)
    }
    return Promise.reject(response.data)
  },
  (err) => {
    store.commit("app/SET_LOADING", false)
    console.log(err.message)
    return Promise.reject(err)
  }
)

export default http
