<template>
  <div class="ourservices ff1">
    <div class="htits1">our services</div>
    <div class="os-t1">
      Experience the authentic treatments ranging from Thainess oil treatment to
      foot treatment!
    </div>
    <div class="os-award">
      <div class="osa-item"><img src="@/assets/images/a_MS-record.jpg" /></div>
      <div class="osa-item">
        <img src="@/assets/images/a_golden-bull.jpg" />
      </div>
      <div class="osa-item"><img src="@/assets/images/a_asean.jpg" /></div>
      <div class="osa-item"><img src="@/assets/images/a_1.jpg" /></div>
      <div class="osa-item"><img src="@/assets/images/a_2.jpg" /></div>
      <div class="osa-item"><img src="@/assets/images/a_3.jpg" /></div>
      <div class="osa-item"><img src="@/assets/images/a_4.jpg" /></div>
    </div>
    <div class="os-wrapper">
      <SimpleSeamlessScrolling :list="servicesData">
        <template #default="{ item }">
          <div class="os-item">
            <img :src="item.img" />
            <div class="t">{{ item.tit }}</div>
          </div>
        </template>
      </SimpleSeamlessScrolling>
    </div>
    <div class="os-ft">
      <a href="https://book.healthland.com.my/booking/selectshop" class="hbtn1"
        >book now!</a
      >
      <a href="https://book.healthland.com.my/about/allservices" class="hbtn2"
        >view all treatments</a
      >
    </div>
  </div>
</template>
<script setup>
import SimpleSeamlessScrolling from "simple-seamless-scrolling"
const servicesData = [
  {
    img: "/assets/images/os1.jpg",
    tit: "thainess oil treatment",
  },
  {
    img: "/assets/images/os2.jpg",
    tit: "thai traditional treatment",
  },
  {
    img: "/assets/images/os3.jpg",
    tit: "foot treatment",
  },
]
</script>
