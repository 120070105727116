<template>
  <div class="tmheader ff1">
    <div class="headerbg">
      <VideoPlayer
        url="assets/video/video1.mp4"
        mobile-url="assets/video/video1_mobile.mp4"
      ></VideoPlayer>
    </div>
    <nav class="navbar" :class="{ navbarshow: hasShowMenu }">
      <div class="container-fluid tmnavbar">
        <a class="navbar-brand" title="Healthland" href="/" style="flex: 1">
          <img
            src="@/assets/images/logo_new.png"
            height="68"
            class="bgblock"
            alt="Healthland"
          />
          <img
            src="@/assets/images/logo_new.png"
            height="50"
            class="smblock"
            alt="Healthland"
          />
        </a>
        <ul class="hmenulist" :class="{ menushow: hasShowMenu }">
          <li class="nav-item">
            <router-link to="/services">services</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about">about</router-link>
          </li>
          <li class="nav-item">
            <a href="https://book.healthland.com.my/about/outlets">outlets</a>
          </li>
          <li class="nav-item">
            <a href="https://book.healthland.com.my/auth/login">membership</a>
          </li>
          <li class="nav-item">
            <router-link to="/news">news</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contact">contact</router-link>
          </li>
        </ul>
        <div class="htopbook">
          <a
            href="https://book.healthland.com.my/about/allservices"
            class="hbtn2"
            >booking now!</a
          >
        </div>
        <div class="tmmenu-toggler">
          <a
            href="#"
            class="tmnb-toggler"
            @click="showMenu()"
            :class="{ show: !hasShowMenu }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="v-icon"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </a>
          <a
            href="#"
            class="tmnb-close"
            @click="closeMenu()"
            :class="{ show: hasShowMenu }"
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="v-icon"
              viewBox="0 0 16 16"
            >
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </a>
        </div>
      </div>
    </nav>
    <div class="brandintro">
      <div class="htits1">one-stop wellness centre</div>
      <div class="bi-t2">Your trusted brand, <br />since 2013</div>
      <div class="bi-book">
        <a
          href="https://book.healthland.com.my/booking/selectshop"
          class="hbtn2"
        >
          Book your session now!
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue"
import VideoPlayer from "@/components/videoplayer.vue"

const showVideo = ref(false)

//菜单显示或关闭
const hasShowMenu = ref(false)
const showMenu = () => {
  hasShowMenu.value = true
}
const closeMenu = () => {
  hasShowMenu.value = false
}
</script>
