<template>
  <div class="membership ff1">
    <div class="ms-bg">
      <img src="@/assets/images/ms-bg.jpg" class="bgblock" />
      <img src="@/assets/images/ms-bg_mobile.jpg" class="smblock" />
    </div>
    <div class="ms-body">
      <div class="htits1">membership</div>
      <div class="ms-t1">Unlock tons of<br />special privileges</div>
      <div class="ms-t2">with Health Taro membership!</div>
    </div>
    <div class="ms-body2">
      <div class="ms-wrapper">
        <SimpleSeamlessScrolling :list="listData">
          <template #default="{ item }">
            <div class="ms-item">
              <div class="msi-t">{{ item.tit }}</div>
              <div class="msi-i"><img :src="item.img" /></div>
            </div>
          </template>
        </SimpleSeamlessScrolling>
      </div>
      <div class="ms-more">and many more!</div>
    </div>
  </div>
</template>
<script setup>
import SimpleSeamlessScrolling from "simple-seamless-scrolling"
const listData = [
  {
    img: "/assets/images/hua_1.svg",
    tit: "Enjoy special membership rate on Happy Hour",
  },
  {
    img: "/assets/images/hua_2.svg",
    tit: "Normal hour price discount",
  },
  {
    img: "/assets/images/hua_3.svg",
    tit: "Share membership benefits with friends and family",
  },
  {
    img: "/assets/images/hua_4.svg",
    tit: "Credit packages applicable on member price",
  },
  {
    img: "/assets/images/hua_5.svg",
    tit: "More privilege to come",
  },
]
</script>
