const states = {
  loading: false,
  routepath: '',
  openId: '',
};

const mutations = {
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_ROUTEPATH: (state, path) => {
    state.routepath = path;
  },
  SET_OPENID: (state, openId) => {
    state.openId = openId;
  },
};

const actions = {};

export default {
  namespaced: true,
  state: states,
  mutations,
  actions,
};
