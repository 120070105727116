import "@/assets/css/layout.css"
import "@/assets/css/healthland.css"
import "@/assets/css/newhome.css"
import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

createApp(App).use(store).use(router).mount("#app")

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0) //強制回到頂部
})
