<template>
  <div class="ouroutlets ff1">
    <div class="oo-bg">
      <img src="@/assets/images/oo_bg.jpg" class="bgblock" />
      <img src="@/assets/images/oo_bg_mobile.jpg" class="smblock" />
    </div>
    <div class="oo-body">
      <div class="htits1">our outlets</div>
      <div class="oo-t1">
        <div>{{ outletNum }}</div>
        <p>+</p>
      </div>
      <div class="oo-t2">Outlets in Klang Valley, Melaka and Penang</div>
      <div class="oo-more">
        <a
          href="https://book.healthland.com.my/booking/selectshop"
          class="hbtn2"
          >view all outlets</a
        >
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue"
import { getOutletNum } from "@/api/news.js"

const outletNum = ref(0)
const init = () => {
  getOutletNum().then((res) => {
    outletNum.value = res.data
  })
}
onMounted(() => {
  init()
})
</script>
