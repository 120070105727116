import http from "./http"

export function getNewsList() {
  return http.get("/api/HLOfficialWeb/newslist")
}

export function getNewsTopList() {
  return http.get("/api/HLOfficialWeb/newstoplist")
}

export function getNewsDetails(newsId) {
  return http.get("/api/HLOfficialWeb/newsdetails", { id: newsId })
}

//获取店铺数量
export function getOutletNum() {
  return http.get("/api/HLOfficialWeb/getoutletnum")
}
