<template>
  <div class="vplayerwarpper">
    <div class="vplayerinner">
      <video
        class="hvideo"
        webkit-playsinline="true"
        playsinline="true"
        ref="videoObj"
        :src="videoUrl"
        loop="loop"
        autoplay
        tabindex="-1"
        aria-hidden="true"
        style="width: 100%"
        muted
      >
        <source :src="videoUrl" type="video/mp4" />
      </video>
    </div>
    <!--
        <div class="vplayercontrols">
            <button v-if="!hasPlay" type="button" @click="play()" class="vplaybtn">
                <svg class="v-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                </svg>
            </button>
            <button v-if="hasPlay" type="button" @click="pause()" class="vplaybtn">
                <svg class="v-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                </svg>
            </button>
        </div>
        -->
  </div>
</template>
<script setup>
import {
  getCurrentInstance,
  onMounted,
  ref,
  defineProps,
  watch,
  computed,
} from "vue"
const props = defineProps({
  url: {
    type: String,
    require: true,
  },
  mobileUrl: {
    type: String,
    require: true,
  },
})
const videoUrl = ref(props.url)
const hasPlay = ref(false)
const internalInstance = getCurrentInstance()

const changeUrl = (width) => {
  if (width > 800) {
    videoUrl.value = props.url
  } else {
    videoUrl.value = props.mobileUrl
  }
}
const play = () => {
  //ios设置autoplay属性无效，需要先load再play
  //internalInstance.proxy.$refs.videoObj.load();  //加了会一直闪
  internalInstance.proxy.$refs.videoObj.play()
}

const pause = () => {
  internalInstance.proxy.$refs.videoObj.pause()
}

onMounted(() => {
  const vPlayer = internalInstance.proxy.$refs.videoObj
  vPlayer.addEventListener("play", () => {
    hasPlay.value = true
  })
  vPlayer.addEventListener("pause", () => {
    hasPlay.value = false
  })
  vPlayer.addEventListener("canplay", () => {
    play()
  })

  //屏幕尺寸监控
  changeUrl(document.body.clientWidth)
  window.addEventListener("resize", () => {
    changeUrl(document.body.clientWidth)
  })
})
</script>

<style scoped>
.vplayerwarpper {
  position: relative;
  width: 100%;
  display: flex;
}
.vplayerwarpper .vplayerinner {
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.vplayerwarpper .vplayercontrols {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.vplaybtn {
  background: transparent;
  border: none;
}
.vplaybtn .v-icon {
  width: 30px;
  height: 30px;
  color: #fff;
}
.hvideo {
  /*
        transition: opacity .3s cubic-bezier(.39,.575,.565,1);
        opacity: 0;
        */
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.hvideo::--webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none !important;
}
</style>
