import { watch, unref } from "vue"
import { useTitle as usePageTitle } from "@vueuse/core"
import { useRouter } from "vue-router"

//监听页面更改和动态更改网站标题
export function useTitle() {
  const title = "HealthLand"
  const { currentRoute } = useRouter()
  const pageTitle = usePageTitle()

  watch(
    [() => currentRoute.value.path],
    () => {
      const route = unref(currentRoute)
      const tTitle = route?.meta?.title
      pageTitle.value = tTitle ? ` ${tTitle} - ${title} ` : `${title}`
    },
    { immediate: true }
  )
}
