import { createRouter, createWebHashHistory } from "vue-router"
import HomeView from "../views/HomeView.vue"

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "HealthLand - Get More Out Of Life",
    },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About/AboutView.vue"),
    meta: {
      title: "Our Story",
    },
  },
  {
    path: "/value",
    name: "value",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About/ValueView.vue"),
    meta: {
      title: "Value & Vission",
    },
  },
  {
    path: "/team",
    name: "team",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About/TeamView.vue"),
    meta: {
      title: "THE TEAM BEHIND",
    },
  },
  {
    path: "/gallery",
    name: "gallery",
    component: () =>
      import(
        /* webpackChunkName: "gallery" */ "../views/About/GalleryView.vue"
      ),
    meta: {
      title: "Gallery",
    },
  },
  {
    path: "/services",
    name: "services",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About/ServicesView.vue"),
    meta: {
      title: "Our Services",
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About/ContactView.vue"),
    meta: {
      title: "Contact Us",
    },
  },
  {
    path: "/career",
    name: "career",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About/CareerView.vue"),
    meta: {
      title: "Career",
    },
  },
  {
    path: "/group",
    name: "group",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About/GroupView.vue"),
    meta: {
      title: "Group of companies",
    },
  },
  {
    path: "/news",
    name: "NewsList",
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/News/index.vue"),
    meta: {
      title: "News",
    },
  },
  {
    path: "/news/details/:newsId",
    name: "NewsDetails",
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/News/details.vue"),
    meta: {
      title: "News Details",
    },
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

export default router
