<template>
  <div class="habout ff1">
    <div class="ha-bg">
      <img src="@/assets/images/ha-bg.jpg" class="bgblock" />
      <img src="@/assets/images/ha-bg_mobile.jpg" class="smblock" />
    </div>
    <div class="ha-box">
      <div class="ha-logo">
        <img src="@/assets/images/logo_booking.png" />
      </div>
      <div class="ha-t1">Welcoming people from all walks of life</div>
      <div class="ha-con">
        HealthLand began its journey with the vision to empower people with
        better health and well-being and enrich their quality of life through
        premium wellness treatments. <br /><br />
        To ensure quality wellness treatments are accessible to everyone,
        HealthLand puts emphasis on providing 5-star services with a 3-star
        price tag. With affordability and high standards of quality at the heart
        of their offerings, HealthLand has since become a ‘household brand’ for
        many health-conscious folks looking for wellness services that they can
        trust.
      </div>
      <div class="ha-book">
        <a
          href="https://book.healthland.com.my/booking/selectshop"
          class="hbtn1"
          >book now!</a
        >
      </div>
    </div>
  </div>
</template>
