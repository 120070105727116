/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import axios from "axios"

class AxiosRequest {
  // 构造函数
  constructor(instance, interceptorRequst, interceptorResponse) {
    // 处理实例
    this._axiosInstance =
      instance === undefined ? this.getDefaultInstance() : instance

    // 拦截器
    if (interceptorRequst === undefined) {
      // 不添加默认的（手动添加）
      // this.addDefaultRequestInterceptor(); // 加载默认请求拦截器
    } else {
      this._interceptorRequst = interceptorRequst
      this._axiosInstance.interceptors.request.use(interceptorRequst, (err) =>
        Promise.error(err)
      )
    }

    // 拦截器
    if (interceptorResponse === undefined) {
      // 不添加默认的（手动添加）
      // this.addDefaultResponseInterceptor(); // 加载返回请求拦截器
    } else {
      this._axiosInstance.interceptors.response.use(interceptorResponse)
    }
  }

  reload(config) {
    console.log("reLoad")
    console.log(config)
    this._axiosInstance(this._interceptorRequst(config))
  }

  request(url, params, requestMethod) {
    console.time(url) // 查看接口请求时间
    const promise = new Promise((resolve, reject) => {
      let data = {}
      const method = requestMethod || "get" // 默认get
      if (method === "get") {
        data = { params }
      }
      if (method === "post") {
        data = { data: params }
      }
      this._axiosInstance({
        url,
        method,
        ...data,
      })
        .then((res) => {
          console.timeEnd(url)
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {})
    })
    return promise
  }

  get(url, params) {
    return this.request(url, params, "get")
  }

  post(url, params) {
    return this.request(url, params, "post")
  }

  async getAsync(url, params, successHandler) {
    const result1 = await this.request(url, params, "get")
    console.log(`result:${JSON.stringify(result1)}`)
    successHandler(result1)
  }

  getData(url, params) {
    console.log("getData")
    // return this.request(url, params, 'get');

    // var p = this.request(url, params, 'get');
    const data = this.getAsync(url, params)
    console.log(`getData${JSON.stringify(data)}`)
    return data
  }

  async getImageAsync(url) {
    // 将后台的图片二进制流传华为base64
    const imgUrl = await this._axiosInstance({
      method: "get",
      url,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      responseType: "arraybuffer", // 关键 设置 响应类型为二进制流
    }).then(
      (response) =>
        `data:image/png;base64,${btoa(
          new Uint8Array(response).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        )}`
    )
    return imgUrl
  }

  // 获取默认实例
  getDefaultInstance() {
    return this.axios.create({
      baseURL: "",
      timeout: 10000,
    })
  }

  // 添加请求拦截器
  addDefaultRequestInterceptor() {
    this._axiosInstance.interceptors.request.use(
      (config) => {
        // console.log("Enter:defaultInterceptorRequest");

        const token = window.localStorage.getItem("token")
        // token && (config.headers.Authorization = token);
        // config.headers.token = 'token';
        return config
      },
      (err) => Promise.error(err)
    )
  }

  // 添加返回拦截器
  addDefaultResponseInterceptor() {
    this.defaultInterceptorResponse =
      this._axiosInstance.interceptors.response.use(
        (response) => {
          // console.log(response);

          // 请求功能200相关的返回状态
          const resStatus = response.status
          if (resStatus == 200) {
            return Promise.resolve(response.data)
          }
          return Promise.reject(response.data)
        },
        (err) => {
          console.log(`Err:${JSON.stringify(err)}`)
          console.log(`Err:${JSON.stringify(err.response)}`)
          if (err.message === "Network Error") {
            console.log(err.message)
          }
          if (err.response) {
            switch (err.response.status) {
              case "403":
                // 403 //handle server forbidden error
                console.log("403")
                break
              case "401":
                // 401认证权限错误(handle authorization error)
                console.log("401")
                break
              case "404":
                // 404
                console.log("404")
                break
              default:
                break
            }
            return Promise.reject(err.response)
          }
          if (!window.navigator.onLine) {
            // 网络不在线
            console.log(
              "The network request is abnormal. Please try again later."
            )
            return -1
          }
          return Promise.reject(err)
        }
      )
  }

  useResponseInterceptor(response, error) {
    if (this.defaultInterceptorResponse !== undefined) {
      console.log("del e")
      this._axiosInstance.interceptors.request.eject(
        this.defaultInterceptorResponse
      )
    }
    this._axiosInstance.interceptors.response.use(response, error)
  }
}

export default AxiosRequest
