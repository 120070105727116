<template>
  <div class="hnews ff1">
    <div class="hn-tit">
      <div class="htits1">news</div>
      <div class="hn-t1">What’s happening</div>
    </div>
    <div class="hn-list">
      <div class="hn-item" v-for="item in listData">
        <div class="hni-img">
          <img :src="item.imgUrl" />
        </div>
        <div class="hni-go-wrapper">
          <router-link :to="'/news/details/' + item.id" class="hni-go">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="v-icon"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
              />
            </svg>
          </router-link>
        </div>
        <div class="hni-t">{{ item.title }}</div>
        <div class="hni-d">{{ item.description }}</div>
      </div>
    </div>
    <div class="hn-more">
      <router-link to="/news" class="hbtn1">load more</router-link>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue"
import { getNewsTopList } from "@/api/news.js"

const listData = ref([])

const init = () => {
  getNewsTopList().then((res) => {
    console.log(res)
    if (res.status == 1) {
      listData.value = res.data
    }
  })
}

onMounted(() => {
  init()
})
</script>
